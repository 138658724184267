@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-ExtraBold/Gilroy-ExtraBold.eot');
  src: local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'), url('../fonts/Gilroy-ExtraBold/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../fonts/Gilroy-ExtraBold/Gilroy-ExtraBold.woff') format('woff'), url('../fonts/Gilroy-ExtraBold/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Light/Gilroy-Light.eot');
  src: local('Gilroy Light'), local('Gilroy-Light'), url('../fonts/Gilroy-Light/Gilroy-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Gilroy-Light/Gilroy-Light.woff') format('woff'), url('../fonts/Gilroy-Light/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'klimatlyuks';
  src:  url('../fonts/klimatlyuks/klimatlyuks.eot?c1svea');
  src:  url('../fonts/klimatlyuks/klimatlyuks.eot?c1svea#iefix') format('embedded-opentype'),
  url('../fonts/klimatlyuks/klimatlyuks.ttf?c1svea') format('truetype'),
  url('../fonts/klimatlyuks/klimatlyuks.woff?c1svea') format('woff'),
  url('../fonts/klimatlyuks/klimatlyuks.svg?c1svea#klimatlyuks') format('svg') {}
  font-weight: normal;
  font-style: normal; }
