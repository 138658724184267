// Fonts
$font-gilroy: 'Gilroy', Arial, sans-serif;

// Colors
$color-black: #313131;
$color-black-1: #192126;
$color-orange: #e77718;
$color-blue: #00aeef;
$color-blue-1: #e7f6fd;
$color-blue-light: #e7f6fd;
$color-black: #192126;
$color-gray: #e6e6e6;
$color-gray-1: #f6f6f6;
$color-gray-2: #999999;
