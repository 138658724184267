﻿.category-activity {
  margin-bottom: 40px;
  &__wrapper {
    padding: 40px 60px;
    background-color: $color-gray-1; }
  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0; }
    @include sm {
      width: 40%;
      &:nth-last-child(2) {
        margin-bottom: 0; } }
    @include lg {
      width: 20%;
      margin-bottom: 0; } }
  &__icon {
    margin-right: 25px;
    @include fz(52);
    color: $color-blue; }
  &__link {
    color: $color-blue; } }
