﻿.site-navigation {
  position: fixed;
  left: -100%;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: $color-blue;
  z-index: 6;
  transition: all 0.3s ease;
  &::before {
    @include pseudo;
    left: 0;
    top: -1px;
    width: 100%;
    height: 1px;
    box-shadow: 0 8px 20px 0 rgba(0,0,0,0.5);
    z-index: 6; }
  @include lg {
    width: auto;
    height: auto;
    position: relative;
    left: 0; }
  &.toggled {
    left: 0; }
  &__nav-toggle {
    display: flex;
    justify-content: flex-end;
    margin: 30px 0;
    @include lg {
      display: none; }
    .nav-toggle {
      span::before,
      span::after {
        background-color: $color-black; } } }
  &__wrapper {
    display: flex;
    flex-direction: column;
    @include lg {
      flex-direction: row; } }
  &__item {
    &_parent {
      position: relative;
      @include lg;
      &:hover {
        .site-submenu {
          display: block;
          opacity: 1; } } } }
  &__link {
    position: relative;
    display: block;
    padding: 20px;
    text-transform: uppercase;
    color: #ffffff;
    transition: all 0.2s ease;
    &:hover,
    &.active {
      background-color: #ffffff;
      color: $color-black; }
    @include lg {
      padding: 20px 12px; }
    @include xl {
      padding: 20px; }
    &_parent {
      &::after {
        @include pseudo;
        @include center('v');
        right: 0;
        font-family: 'klimatlyuks' !important;
        @include fz(11);
        color: #ffffff;
        content: "\e900";
        transition: color 0.2s ease;
        @include lg {
          @include center('h');
          top: auto;
          right: auto;
          bottom: 6px;
          content: "\e912"; } }
      &:hover {
        background-color: #ffffff;
        &::after {
          color: $color-black; } } } } }

.nav-toggle {
  display: block;
  width: 28px;
  height: 30px;
  cursor: pointer;
  span {
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    &::after,
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -9px; }
    &::after {
      top: 9px; } }
  span,
  span::after,
  span::before {
    width: 100%;
    height: 5px;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px; }
  &_close {
    span {
      background-color: transparent;
      &::before {
        top: 0;
        transform: rotate(45deg); }
      &::after {
        top: auto;
        bottom: 0;
        transform: rotate(-45deg); } } } }

.site-submenu {
  width: 100%;
  background-color: $color-blue;
  transition: all 0.2s ease;
  @include lg {
    position: absolute;
    display: none;
    opacity: 0; }
  &__link {
    display: block;
    padding: 15px 10px 15px 25px;
    color: #ffffff;
    transition: all 0.2s ease;
    &:hover {
      color: $color-black-1;
      background-color: #ffffff; } } }
