.text {
	margin-bottom: 40px;
	@include fz(18);
	&_first {
		margin-top: 40px; }
	&__heading {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		margin-bottom: 30px;
		@include fz(30);
		@include xl {
			flex-direction: row; }
		h3 {
			margin-bottom: 10px;
			font-size: inherit;
			@include xl {
				margin: 0 10px 0 0; } } }
	&__content {
		p {
			margin-bottom: 25px;
			line-height: 30px; }
		ul {
			li {
				&:last-child {
					margin-bottom: 0; }
				&::before {
					top: -7px;
					@include fz(34);
					content: "\2022"; } } }
		ol {
			counter-reset: li;
			li {
				counter-increment: li;
				&::before {
					top: 2px;
					@include fz(18);
					content: counter(li); } } }
		ul,
		ol, {
			margin-bottom: 30px;
			padding-left: 20px;
			li {
				position: relative;
				margin-bottom: 18px;
				&::before {
					position: absolute;
					left: -17px;
					color: $color-blue; } } } }
	&__quote {
		position: relative;
		padding: 15px;
		background-color: $color-blue;
		color: #ffffff;
		@include md {
			padding: 30px; }
		img {
			position: absolute;
			right: 15px;
			top: -7px; } }
	&__img {
		width: auto;
		max-width: 100%;
		height: auto;
		&_center {
			margin-bottom: 30px; }
		&_left {
			float: left;
			margin-right: 30px; }
		&_right {
			float: right;
			margin-left: 30px; } }
	&__more {
		display: flex;
		align-items: center;
		color: $color-blue;
		cursor: pointer;
		span {
			margin-left: 5px; } } }

.social-share {
	margin-bottom: 40px;
	&__wrapper {
		padding: 20px 0;
		background-color: $color-gray-1;
		text-align: center; }
	&__img {
		width: auto;
		max-width: 100%; } }
