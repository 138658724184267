﻿.brands {
  margin-bottom: 40px;
  &-list {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    &_slider {
      margin-left: 30px;
      .slick-slide {
        margin-right: 30px; }
      .slick-arrow {
        position: absolute;
        @include center('v');
        cursor: pointer;
        &::before {
          @include fz(26);
          color: $color-blue; } }
      .icon-arrow-left {
        left: -40px;
        @include xl {
          left: -5%; } }
      .icon-arrow-right {
        right: -1%;
        @include xl {
          right: -2%; } } }
    &__item {
      display: flex !important;
      align-items: stretch;
      height: 80px; }
    &__link {
      display: flex;
      align-items: center;
      padding: 20px 10px;
      border: 1px solid $color-gray; }
    &__img {
      width: 100%;
      max-height: 50px; } } }

#brands {
  padding-top: 40px;
  .brands {
    &-list {
      flex-wrap: wrap;
      margin-bottom: -15px;
      &__item {
        width: 48%;
        margin-bottom: 15px;
        @include xs {
          width: 30%; }
        @include md {
          width: 23%; }
        @include xl {
          width: 15%; } } } } }
