@import "normalize";
@import "fonts";
@import "typography";

html,
body {
  width: 100%;
  height: 100%; }

body {
  position: relative;
  min-width: 320px;
  background-color: #ffffff; }

* {
  margin: 0;
  padding: 0; }

a,
a {
  text-decoration: none;
  transition: color 0.2s ease;
  &:hover {
    text-decoration: none;
    color: $color-black-1; } }

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none; }

.container {
  margin: 0 auto;
  padding: 0 15px;
  @include sm {
    max-width: 540px; }
  @include md {
    max-width: 720px; }
  @include lg {
    max-width: 960px; }
  @include xl {
    max-width: 1140px; } }

.section-title {
  margin-bottom: 40px;
  @include fz(33);
  text-align: center;
  color: $color-black; }
