﻿.site-header {
  padding: 30px 0;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
  &__logo {
    max-width: 190px;
    margin-bottom: 20px;
    @include lg {
      margin-bottom: 0; } }
  &-info {
    margin-bottom: 20px;
    padding-left: 30px;
    @include sm {
      width: 45%;
      margin-bottom: 0; }
    @include lg {
      width: auto; }
    &__item {
      position: relative;
      margin-bottom: 17px;
      color: $color-black-1;
      &:last-child {
        margin-bottom: 0; }
      [class^="icon-"] {
        position: absolute;
        left: -25px;
        top: 0;
        &::before {
          color: $color-blue; } } } }
  &-contacts {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include sm {
      width: 45%; }
    @include lg {
      width: auto;
      flex-direction: row; }
    &__list {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      list-style: none;
      @include sm {
        flex-direction: column;
        margin-bottom: 10px; }
      @include md {
        flex-direction: row; }
      @include lg {
        flex-direction: column;
        margin: 0 20px 0 0; } }
    &__item {
      width: 45%;
      margin-bottom: 13px;
      &:last-child {
        margin-bottom: 0; }
      @include sm {
        width: 100%; }
      @include md {
        margin-bottom: 0; } }
    &__link {
      @include fz(15);
      font-weight: 800;
      color: $color-black-1;
      @include lg {
        @include fz(18); }
      @include xl {
        @include fz(24); } }
    &__btn {
      padding: 15px 25px; } }
  &__nav-toggle {
    display: flex;
    justify-content: flex-end;
    width: 30%;
    @include sm {
      width: 45%; }
    @include lg {
      display: none; }
    .nav-toggle {
      margin-bottom: 30px;
      span,
      span::before,
      span::after {
        background-color: $color-blue; } } } }

.logo {
  &__link {
    display: block; }
  &__img {
    width: 100%; } }
