.advantages {
	margin-bottom: 40px;
	padding: 40px 0;
	background-color: $color-blue-1;
	&-list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 15px;
		text-align: center; }
	&-item {
		width: 42%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 30px;
		@include md {
			width: 17%;
			margin-bottom: 0; }
		@include lg {
			width: 25%; }
		&__icon {
			margin-bottom: 30px;
			padding: 30px;
			border-radius: 50%;
			background-color: #ffffff;
			&::before {
				@include fz(60);
				color: $color-blue; } }
		&__text {
			@include fz(18);
			color: $color-black; } } }
