﻿.calculation {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    @include lg {
      flex-wrap: nowrap; } }
  &-calc {
    width: 100%;
    padding: 30px;
    background-color: $color-gray-1;
    @include lg {
      width: 100%/12*8; }
    &__title {
      margin-bottom: 30px; }
    &__item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0; } }
    &__heading {
      width: 100%;
      margin-bottom: 10px;
      @include fz(18); }
    &__input {
      width: 100%;
      margin-bottom: 10px;
      padding: 10px 15px;
      border: 1px solid $color-gray;
      @include fz(18);
      @include md {
        width: 50%;
        margin-right: 30px; }
      @include lg {
        width: 100%/12*6; } }
    &__option {
      display: flex;
      align-items: center; } }
  &-result {
    width: 100%;
    padding: 30px;
    background-color: $color-blue-1;
    @include lg {
      width: 100%/12*4; }
    &__title {
      margin-bottom: 30px;
      @include fz(24); }
    &__item {
      margin-bottom: 30px;
      font-weight: 800;
      &:last-child {
        margin-bottom: 0; } }
    &__heading {
      display: block;
      margin-bottom: 20px; }
    &__value {
      @include fz(30);
      color: $color-blue; } } }

.help {
  position: relative;
  display: inline-block;
  margin-left: 15px;
  padding: 2px 8px;
  border-radius: 50%;
  background-color: $color-blue;
  text-align: center;
  @include fz(18);
  color: #ffffff;
  cursor: pointer;
  &::before {
    position: absolute;
    bottom: 110%;
    @include center('h');
    display: none;
    content: attr(data-title);
    padding: 5px;
    background-color: $color-blue; }
  &:hover {
    &::before {
      display: block; } } }
