.consult {
	position: relative;
	margin-bottom: 40px;
	padding: 30px 0 35px;
	background-color: $color-blue;
	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
		@include sm {
			padding-top: 40px; }
		@include lg {
			flex-direction: row;
			padding-top: 55px; }
		@include xl {
			padding-top: 0; } }
	&__img {
		position: absolute;
		@include center('h');
		top: -10%;
		width: auto;
		height: 90px;
		@include xl {
			height: 120%;
			left: 44%; } }
	&__text {
		position: relative;
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;
		@include fz(24);
		color: #ffffff;
		z-index: 2;
		@include sm {
			flex-direction: row; }
		@include lg {
			flex-direction: column;
			margin-bottom: 0; }
		@include xl {
			flex-direction: row; }
		h6 {
			margin-right: 5px;
			@include fz(24); } }
	&__form {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		z-index: 2;
		@include sm {
			flex-direction: row;
			justify-content: space-between; }
		@include lg {
			width: 60%; }
		@include xl {
			width: 42%; } }
	&__phone {
		margin-bottom: 30px;
		@include sm {
			width: 43%;
			margin: 0; } }
	&__btn {
		padding: 20px 30px;
		@include sm {
			width: 43%; } } }
