input,
textarea {
	padding: 20px;
	border-radius: 0;
	border: none;
	box-shadow: none;
	&::placeholder {
		text-transform: uppercase;
		@include fz(16);
		font-weight: 800;
		color: $color-gray-2; } }

.label-checkbox {
	display: inline-block;
	input {
		width: 0;
		height: 0;
		visibility: hidden;
		&:checked + label {
			&::after {
				content: "\e902"; } } }
	label {
		position: relative;
		padding-left: 30px;
		line-height: 1.6;
		&::before {
			@include pseudo;
			left: 0;
			top: -2px;
			display: block;
			width: 20px;
			height: 20px;
			background-color: #ffffff;
			border: 1px solid $color-gray-1; }
		&::after {
			position: absolute;
			display: block;
			left: 4px;
			top: 3px;
			@include fz(12);
			font-family: 'klimatlyuks' !important;
			color: $color-blue; } } }
