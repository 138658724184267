// Responsive break-points
@mixin r($width) {
  @media only screen and (min-width: $width+ "px") {
    @content; } }

@mixin xs {
  @include r(480) {
    @content; } }

@mixin sm {
  @include r(576) {
    @content; } }

@mixin md {
  @include r(768) {
    @content; } }

@mixin lg {
  @include r(992) {
    @content; } }

@mixin xl {
  @include r(1200) {
    @content; } }

// font-size
@mixin fz($sizeValue: 16) {
  font-size: $sizeValue / 16 + rem; }

// vertical-align at center
@mixin center($axis) {
  position: absolute;
  @if $axis == both {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); }
  @else if $axis == h {
        left: 50%;
        transform: translateX(-50%); }

  @else if $axis == v {
        top: 50%;
        transform: translateY(-50%); } }

// pseudo element
@mixin pseudo {
  position: absolute;
  display: block;
  content: ''; }
