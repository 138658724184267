﻿input[type='submit'],
button,
.btn,
.button {
  text-transform: uppercase;
  background-color: transparent;
  box-shadow: none;
  border: none;
  font-weight: 800;
  cursor: pointer; }

input[type='submit'].primary,
button.primary,
.btn__primary,
.button__primary {
  background-color: $color-orange;
  color: #ffffff;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: lighten($color-orange, 10%); } }

input[type='submit'].secondary,
button.secondary,
.btn__secondary,
.button__secondary {
  background-color: transparent;
  border: 3px solid $color-orange;
  color: $color-orange;
  transition: all 0.2s ease;
  &:hover {
    background-color: $color-orange;
    color: $color-black-1; } }

input[type='submit'].icon,
button.icon {}
.btn__icon,
.button__icon {
  position: absolute;
  @include center('v');
  @include fz(24);
  margin-right: 15px; }
