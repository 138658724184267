﻿.certificates {
  margin-bottom: 40px;
  &-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include lg {
      flex-wrap: nowrap; }
    &__item {
      width: 40%;
      margin-bottom: 30px;
      padding: 10px;
      border: 1px solid $color-gray;
      @include md {
        width: 28%; }
      @include lg {
        width: auto;
        margin-right: 30px;
        &:last-child {
          margin-right: 0; } } }
    &__img {
      width: 100%; } } }
