﻿.power-calc {
  margin-bottom: 40px;
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 30px;
    background-size: cover;
    background-position: bottom right;
    background-repeat: no-repeat;
    @include md {
      flex-direction: row; } }
  &__btn {
    position: relative;
    margin-bottom: 20px;
    padding: 10px 23px 10px 60px;
    z-index: 2;
    @include md {
      margin: 0 20px 0 0; }
    span {
      left: 23px; } }
  &__text {
    position: relative;
    color: #ffffff;
    @include fz(24);
    z-index: 2;
    @include xl {
      width: 75%; }
    b,
    strong {
      font-weight: 800; } } }
