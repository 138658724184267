body {
  font-size: 16px;
  font-family: $font-gilroy;
  font-weight: 300;
  color: $color-black-1; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'klimatlyuks' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-right:before {
  content: "\e900"; }

.icon-calendar:before {
  content: "\e901"; }

.icon-check:before {
  content: "\e902"; }

.icon-conditioning:before {
  content: "\e903"; }

.icon-crane:before {
  content: "\e904"; }

.icon-earth:before {
  content: "\e905"; }

.icon-heating:before {
  content: "\e906"; }

.icon-house:before {
  content: "\e907"; }

.icon-house-ventilation:before {
  content: "\e908"; }

.icon-lightning:before {
  content: "\e909"; }

.icon-like:before {
  content: "\e90a"; }

.icon-map-point:before {
  content: "\e90b"; }

.icon-phone:before {
  content: "\e90c"; }

.icon-planning:before {
  content: "\e90d"; }

.icon-repair:before {
  content: "\e90e"; }

.icon-truck:before {
  content: "\e90f"; }

.icon-ventilation:before {
  content: "\e910"; }

.icon-water:before {
  content: "\e911"; }

.icon-arrow-down:before {
  content: "\e912"; }

.icon-arrow-left:before {
  content: "\e913"; }

.icon-engineer:before {
  content: "\e914"; }

.icon-wallet:before {
  content: "\e915"; }

.icon-maintenance:before {
  content: "\e916"; }

.icon-mont-electro:before {
  content: "\e917"; }

.icon-mont-water:before {
  content: "\e918"; }
