﻿.main-slider {
  margin: 40px 0;
  .slick-list {
    padding: 20px 0; }
  .slick-dots {
    position: absolute;
    bottom: 10%;
    @include center('h');
    display: flex;
    z-index: 5;
    @include md {
      left: auto;
      right: 5%;
      transform: none; }
    @include lg {
      left: 5%;
      right: auto; }
    li {
      margin-right: 5px; } }
  .slick-active {
    .slick-dot {
      opacity: 1; } }
  .slick-dot {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0.4;
    cursor: pointer;
    &:last-child {
      margin-right: 0; }
    @include lg {
      width: 15px;
      height: 15px; } }
  &__item {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    padding: 50px 15px;
    width: auto !important;
    height: 150px;
    background-size: cover;
    @include md {
      flex-direction: row; }
    @include lg {
      height: 250px; }
    @include xl {
      height: 300px; } }
  &__img {
    position: absolute;
    top: -5%;
    @include center('h');
    max-height: 60%;
    width: auto;
    @include md {
      left: 5%;
      max-height: 110%;
      transform: none; }
    @include lg {
      left: 15%; } }
  &__text {
    text-align: center;
    @include fz(22);
    line-height: 1.3;
    color: #ffffff;
    @include md {
      width: 45%;
      text-align: right;
      @include fz(26);
      line-height: 1.6; }
    @include lg {
      @include fz(36); } } }
