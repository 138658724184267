﻿.categories {
  margin-bottom: 40px;
  &__wrapper {
    padding: 30px;
    background-color: $color-gray-1; }
  &-list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include md {
      flex-direction: row; }
    &_img {
      flex-direction: row;
      flex-wrap: wrap; }
    &__item {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0; }
      @include md {
        width: 30%;
        margin-bottom: 0; }
      @include xl {
        flex-direction: row; }
      &_img {
        width: 100%;
        text-align: center;
        @include xs {
          width: 45%; }
        @include xl {
          width: 14%;
          flex-direction: column; } } }
    .category-list {
      &__icon {
        margin-bottom: 15px;
        text-align: center;
        &::before {
          @include fz(50);
          color: $color-blue; }
        @include md {
          text-align: left; }
        @include xl {
          margin-right: 20px; } }
      &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 170px; }
      &__title {
        margin-bottom: 20px;
        @include fz(18);
        color: #000000; }
      &__item {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0; } }
      &__link {
        color: $color-blue; } } } }
