﻿.category-benefits {
  margin-bottom: 40px;
  &__wrapper {
    background-color: $color-gray-1; }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 60px 10px 30px;
    @include lg {
      padding-bottom: 60px; } }
  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
    @include xs {
      width: 45%; }
    @include md {
      width: 27%; }
    @include lg {
      width: 14%;
      margin-bottom: 0; } }
  &__img {
    width: auto;
    height: auto;
    max-width: 100%;
    min-height: 125px;
    margin-bottom: 30px; }
  &__link {
    text-align: center;
    color: $color-blue; } }
