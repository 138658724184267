﻿.site-footer {
  padding: 50px 0;
  background-color: $color-gray-1;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include sm {
      flex-direction: row;
      flex-wrap: wrap; } }
  &-list {
    width: 100%;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0; }
    @include sm {
      width: 48%; }
    @include lg {
      width: 19%; }
    @include xl {
      &:first-child {
        width: 16%; }
      &:last-child {
        width: 25%; } }
    &__title {
      margin-bottom: 20px;
      @include fz(22);
      color: $color-black; }
    &__item {
      margin-bottom: 17px;
      @include fz(18);
      color: $color-black-1;
      &:last-child {
        margin-bottom: 0; } }
    &__link {
      color: $color-blue;
      &_black {
        color: $color-black; } } } }
