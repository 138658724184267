﻿.form-section {
  margin-bottom: 40px;
  &__wrapper {
     padding: 30px;
     background-color: $color-blue; } }

.message-form {
  &__text,
  &__fields {
    margin-bottom: 30px; }
  &__wrapper,
  &__text {
    display: flex;
    flex-direction: column; }
  &__wrapper {
    @include lg {
      align-items: flex-start;
      width: 28%; } }
  &__file {
    padding: 15px 0;
    text-transform: none;
    font-weight: 300;
    background-color: #ffffff;
    @include fz(18);
    color: $color-blue;
    @include md {
      padding: 19px 25px; } }
  &__btn {
    padding: 15px 25px; }
  &__fields {
    @include md {
      display: flex;
      justify-content: space-between; } }
  label {
    display: block;
    margin-bottom: 10px;
    @include fz(18);
    color: #ffffff; }
  textarea {
    height: 50px;
    margin-bottom: 0;
    resize: none;
    @include lg {
      height: 135px; } }
  input {
    margin-bottom: 15px;
    @include lg {
      width: 100%; } } }
